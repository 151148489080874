.cohortsInner .mainTrialEntries .cohort_label label{
    width: 100%;
}
.enterCohort label.bold_heading_text{
    font-size: 20px !important;
}
.enterCohort .cohort_field{
  margin-top: 3px;
  margin-left: 4px !important;
}
.cohortsInner .mainTrialEntries .enterCohort.lexicon_title input {
  padding: 2px 6px;
}
.appendCohortData .async-select, .appendCohortData label{
    float: left;
    margin-left: 4px;
}
 
.calender_div .form-control{
    height: 26px;
    padding: 8px;
    box-shadow: none;
    font-weight: normal;
    padding-left: 12px;
    box-sizing: border-box;
    border: 1px solid rgba(156, 156, 156, .5);
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 12%);
    height: 35px;
}
.cohort_field input::placeholder,
.calender_div .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #808080;
  font-size: 14px;
  opacity: 1; /* Firefox */
}
.cohort_field input:-ms-input-placeholder,
.calender_div .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000;
}

.cohort_field input::-ms-input-placeholder,
.calender_div .form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #000;
}

.tech-inner-content .tech-inner-content-item {
    display: inline-block;
    margin: 0;
    padding: 8px 0 0 0;
    min-height: 34px;
    width: 100%;
  }
  
  .tech-inner-content:nth-child(odd) {
    background: rgb(251, 251, 251);
  }
  
  .tech-inner-content:nth-child(even) {
    background: #fff;
  }
  
  .tech-inner-content .tech-inner-content-item span {
    color: #2687da;
    overflow-wrap: break-word;
    font-size: 15px;
  }
  
  .tech-inner-content:hover {
    background: #e8f0f8
  }
  .App-CalenderIcon{
    cursor: pointer;
  }
  .measures_outcome select.uniqueTriaArm{
    padding: 4px;
    height: 28px;
  }

  .cohortsInner .mainTrialEntries .enterCohort label {
    float: left;
  }
  .has-error .Select-control{
    border: 1px solid #ea5848;
  }
  .appendCohortData .index-event-select{
    width: 79% !important;
    margin-left: 1px;
  }
  .appendCohortData .event-inclusion{
    width: 79% !important;
    width: 100%;
  }

  .appendCohortData .event-exclusion{
    width: 79.1% !important;
  }
  .appendCohortData .index-event-select,.appendCohortData .event-inclusion,.appendCohortData .event-exclusion{
    margin: 4px !important;
  }
  .appendCohortData label{
    margin-top: 4px;
    margin-bottom: 0;
  }
  label.concept-type{
    margin:0 0 0 4px;
    padding-top: 10px;
  }
  .appendCohortData .editCohort_inclusion{
    margin-top: 4px !important
  }
  .cohortsInner .mainTrialEntries select.custom {
    width: 620px !important;
    margin-left: 3px;
  }
  .cohort_field input[type="text"].error{
    border-color: #f00;
  }
  .cohortPopoverNew .popover-content{
    padding: 9px 30px;
  }
  .cohortPopoverNew h3.popover-title{
    font-size:24px;
  }
  .select-outcome-measure{
    display: inline-block;
    width: 68%;
    margin: 0 4px;
    padding: 0;
  }
  .select-criterion, .select-inclusion{
    display: inline-block;
    width: 220px;
    margin: 0 4px;
    padding: 0;
  }
  .select-criterion{
    float: left;
    margin-right: 8px;
  }
  .select-inclusion{
    width: 60%;
  }
  .saveTrial label{
    width: fit-content !important;
    float: left;
  }

  /*************************CSS CODE FOR REFACTORED COHORT PAGES********************************/
  .v-cohort-name{
    font-size: 20px;
    margin: 18px;
  }
  .v-cohort-name.cohort-name-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 1px;
  }
  #study-validity-dropdown {
    height: 32px;
    width: 32px;
    background:#00b5e0;
    border: none;
  }
  #study-validity-dropdown::after {
    margin-left: -2px;
    font-size: 21px;
    margin-top: 7px;
    
  }
  #study-validity-dropdown:focus {
    outline: none;
    box-shadow: none;
  }
  .study-validity-checkbox {
    color:#00b5e0;
    margin-right: 12px;
  }
  .study-validity-text {
    font-size: 15px;
  }
  .study-validity-wrapper-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .study-validity-dropdown-item{
    padding: 0px 11px;
  }
  .v-criteria-table{
    margin: 21px 25px;
  }
  .v-criteria-table td {
    padding: 8px;
    min-width: 150px;
  }
  .v-criteria-date{
    padding: 32px 0 0 42px;
  }
  /*************************CSS CODE FOR REFACTORED COHORT PAGES********************************/

  /*************************CSS CODE FOR COHORT RESULT TABLE COMPONENT ********************************/
  .outcome-measures {
    
  }
  .outcome-measures table{
    /* width: 100%; */
    margin-top: 16px;
  }
  .outcome-measures .box {
    display: inline-block;
    min-width: 194px;
    padding: 0 0 8px 20px;
    border-left: 1px solid #eaeaea;
    margin: 23px 0 28px 50px;
  }
  .outcome-measures .box h5{
    font-size: 16px;
  }
  .outcome-measures .box .count {
    margin-top: 24px;
    font-size: 36px;
    font-family: roboto-bold;
    color: #777;
    display: inline-block;
  }
  .outcome-measures .box .count-link {
    color: #3980d8;
    cursor: pointer;
  }
  /*************************CSS CODE FOR COHORT RESULT TABLE COMPONENT ********************************/
  img.control-button {
    width: 24px;
    margin-left: 10px;
    float: right;
  }
  .v-cohort-input-holder{
    padding: 8px;
    display: flex;
  }
  .v-cohort-input-holder h3{
    display: inline-block;
    margin: 4px;
    padding-top: 3px;
  }
  .v-cohort-input {
    width: 75%;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 3px;
    padding-left: 8px;
  }
  .v-cohort-input.error {
    border-color: #f00;
  }
  .v-cohort-name{
    font-size: 20px;
  }
  .v-panel-heading, .v-panel-heading-button {
    background: #f4f4f4;
    overflow: auto;
    padding: 8px;
  }
  .v-panel-heading-button{
    padding-left: 15px;
    margin: 18px 0;
  }
  .v-add-cohort label {
    font-weight: normal;
    font-size: 15px;
    margin-top: 2px;
    margin-left: 10px;
  }
  .vertical-space{
    height: 10px;
  }
  .v-cohort-input-holder span {
    margin-left: 134px;
  }
  .criteriaSection{
    width: 87%;
  }
  .cohort_select{
    width:80%;
  }
  .cohort-label{
     width:150px;
     padding-top: 8px;
  }

  .v-add-cohort .addstudy .add-row  {
    padding-left: 49px;
    padding-bottom: 30px;
}

.add-inclusion-exclusion{
  margin-left: 30px;
}
.add-inclusion-exclusion .select-inclusion {
  width: 65.5%;
}