.document-header-container {
    padding: 20px 0px 10px 10px;
    font-size: 20px;
    cursor: default;
    display: inline-block;
    min-width: 300px;
    width:100%;
}

.document-content-container .section-header {
    margin: 0px;
}
.document-content-container .section-text {
    padding: 20px 0 20px 50px;
}

.section-header {
    padding: 8px;
    padding-left: 20px;
    background: #efefef;
    margin: 20px 0 20px 0;
    min-height: 34px;
}

ul.section-container {
    margin: 0;
    padding: 0 0 0 16px;
}

ul.section-container li {
    padding: 0 0 4px;
}

.section-text {
    padding-left: 50px;
    cursor: default;
}



/* .editable .section-text {
    cursor: url("../images/edit_pencil.png"), auto;
} */

#popover-contained {
    position: absolute;
    display: none;
    width: 1000px;
    left: 0;
    top: 0;
    z-index: 1100 !important;
}

#cal1 {
    position: absolute;
    height: 0px;
    width: 0px;
    top: 100px;
    left: 100px;
    overflow: none;
    z-index: -100;
}

#cal2 {
    position: absolute;
    height: 0px;
    width: 0px;
    top: 0px;
    left: 0px;
    overflow: none;
    z-index: -100;
}

.icon-manual-annotation {
    height: 12px;
    width: 12px;
    display: inline-block;
    background: url(../images/annotationicon_45_x_45.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin: 0px 0 5px 0px;
    color: transparent;
}
.icon-manual-annotation::selection {
    color: transparent !important;
}

.editable.section-text span:not(.icon-manual-annotation) {
    cursor: url("../images/edit_pencil.png"), auto;
}

.editable.section-text .icon-manual-annotation{
    opacity: 0.5;
}

.editable.section-text .icon-manual-annotation.temp {
    opacity:1;
}

.editable.section-text .hyperlink, .editable.section-text .hyperlink *:not(.temp) {
    cursor: url("../images/edit_pencil.png"), auto !important;
}


.editable.section-text span::selection {
    color: white;
    background-color: #000;
}


.icon-cohort-annotation {
    height: 12px;
    width: 12px;
    display: inline-block;
    background: url("../images/cohort_blue_icon.png") no-repeat;
    background-size: contain;
    cursor: pointer;
    margin: 0px 0 2px 3px;
}

.highlighted {
    background-color: #000;
    color: White !important;
    color: #fff !important;
}

a.hyperlink.highlighted * {
    background-color: #000;
    color: White !important;
    color: #fff !important;
}

#document-annotation-popup.addmanulaannotation .arrow {
    /* padding:0; */
    left: 230px;
}

#document-annotation-popup {
    min-width: 350px;
}
#document-manual-annotation-popup {
    min-width: 500px;
    max-width: 1000px;
}

#document-annotation-popup .details, #document-manual-annotation-popup .details {
    padding-top: 10px;
}

#document-annotation-popup .details td:first-child, #document-manual-annotation-popup .details td:first-child{
    padding: 0px;
}

#document-annotation-popup .details td strong, #document-manual-annotation-popup .details td strong{
    padding-left: 15px;
}

#document-annotation-popup .details td>span, #document-manual-annotation-popup .details td>span,#document-manual-annotation-popup .details td>span{
    padding-left: 15px;
    display: inline-block;
    width: 100%;
}
#document-manual-annotation-popup .details span input[type=text]:focus,#document-manual-annotation-popup .details span  input[type=number]:focus{
    border-radius: 2px;
    outline-color: #007bff;
}

#document-manual-annotation-popup .details span #observation_date{
    width: 118% !important;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    padding-left: 10px;
    margin-top: 4px;
    height: 32px;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 12%);
}

#document-annotation-popup .details td .codeAnnotation, #document-manual-annotation-popup .details .codeAnnotation{
    width: 100%;
    margin-top: 4px;
}

.document-list .panel-heading {
    padding: 8px !important;
}

.document-list .data-header {
    display: inline-block;
    margin-top: 3px;
}

.product-setting .searchCancel {
    left: 66%;
    top: 6px;
}

.automated-annotation-pop .title {
    font-size: 18px;
    padding: 5px 10px;
    border-bottom: 1px solid #c0c0c0;
    color: #333;
}

.automated-annotation-pop .list {
    padding-top: 10px;
    margin: 0px;
}

.rmv-icon {
    width: 25px;
    display: inline-block;
}

.annotation-name {
    display: inline-block;
    vertical-align: top;
    padding-left: 5px;
}

.toggle-container {
    width: 35px;
}

.selectMessagePopup {
    min-height: 80px !important;
}

.selectMessagePopup .popover-body {
    min-height: 80px;
    padding: 28px;
}

.select-study-popup .Select {
    width: 40%;
    text-align: center;
    display: inline-block;
}

.document-header-container .encounter-name {
    min-width:300px;
    float:left;
}
.document-header-container .selected-concept {
    float: right;
    font-size: 14px;
    /* text-align: right; */
    border: 1px solid #c0c0c0;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: white;
    margin-top: -5px;
    max-width: 451px;
    display: flex;
    justify-content: flex-start;
}
.document-header-container .selected-concept .clear-concept  {
    cursor: pointer;
    margin-left: 9px;
    border: 1px solid #c0c0c0;
    border-radius: 25%;
    height: 20px;
    width: 20px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 16px;
    font-size: 16px;
}

