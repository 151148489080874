
@font-face {
    font-family: "roboto-regular";
	src: url("fonts/helvetica/Helvetica.woff2") format("woff2"), url("fonts/helvetica/Helvetica.woff2") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "roboto-bold";
	src: url("fonts/helvetica/Helvetica-Bold.woff2") format("woff2"),
		url("fonts/helvetica/Helvetica-Bold.woff2") format("woff");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Glyphicons Halflings";
	src: url("fonts/glyphicons-halflings-regular.eot");
	src: url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"),
		url("fonts/glyphicons-halflings-regular.woff2") format("woff2"),
		url("fonts/glyphicons-halflings-regular.woff") format("woff"),
		url("fonts/glyphicons-halflings-regular.ttf") format("truetype"),
		url("fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

@font-face {
	font-family: "roboto-regular";
	src: url("./fonts/Roboto-Regular.woff") format("woff"), url("./fonts/Roboto-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "roboto-medium";
	src: url("./fonts/Roboto-Medium.woff") format("woff"), url("./fonts/Roboto-Medium.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "roboto-bold";
	src: url("./fonts/Roboto-Bold.woff") format("woff"), url("./fonts/Roboto-Bold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "roboto-light";
	src: url("./fonts/Roboto-Light.woff") format("woff"), url("./fonts/Roboto-Light.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}
