.pad-acc-header {
  padding-left: 20px !important;
  margin: 2px;
  line-height: 34px;
}

.pad-sub-option {
  padding-left: 70px !important;
}

/* .searchCancel {
  position: absolute;
  display: inline-block;
  // height: 10px;
  font-size: 18px;
  color: #c0c0c0;
  background-color: #fff;
  left: 69%;
  top: 2px;
  width: 30px;
  text-align: center;
  cursor: pointer;
} */

.text-input{
  /* font-size: 14px; */
	font-weight: normal;
	padding-left: 12px;
	/* width: 100%; */
	box-sizing: border-box;
  border: 1px solid rgba(156, 156, 156, .5);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 12%);
	height: 35px;
}
.text-input:focus{
	outline: none;
}

.logo {
  height: 50px;
  width: 210px;
  background-repeat: no-repeat;
  background-size: 98%;
  position: fixed;
  background-position: 5px;
  left: 0;
  top: 0;
  z-index: 3;
  background-color: #fff;
  display: none;;
}

.footer-copy {
  height: 50px;
  width: 111%;
  position: absolute;
  left: -30px;
  top: 100%;
  border-top: 1px solid #ebebeb;
  /* z-index: 3; */
  text-align: center;
  color: #717273;
  padding: 20px;
}

.footer span {
  color: #717273;
}

.nopadding {
  padding: 0;
  margin: 0;
}

.annotationText {
  width: 100%;
  height: 400px;
}

.manualUploadText {
  width: 100%;
  height: 500px;
  border: 1px solid #c0c0c0;
  padding: 10px;
  resize: none;
  border: 1px solid rgba(156, 156, 156, .5);
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 12%);
  border-radius: 5px;
  box-sizing: border-box;
}

.manualUploadText:focus {
  outline: 0;
}

.datanalyse_filter {
  min-height: auto !important;
}

.datanalyse_filter .filter_option {
  padding-top: 40px;
  font-size: 15px;
  height: 150px;
}

.datanalyse_filter .filter_option .filter_checkbox {
  margin-right: 10px;
}

.datanalyse_filter .filter_option .filter_checkbox:after {
  content: "";
  top: 0;
  left: 10px;
  position: absolute;
  height: 24px;
  width: 24px;
  background-size: 95%;
  background-color: white;
  cursor: pointer;
}

.datanalyse_filter .filter_option .filter_checkbox.checked:after {
  background-image: url(../images/filter-checked.png);
}

.datanalyse_filter .filter_option .filter_checkbox.unchecked:after {
  background-image: url(../images/filter-unchecked.png);
}

.datanalyse_filter .filter_option .filter_input {
  margin-top: 10px;
  margin-left: 24px;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
  cursor: not-allowed;
}

.paginationComponent {
  /* background: #f4f4f4; */
  height: 36px;
  padding: 0;
  margin: 10px 0;
  position: relative;
  z-index: 1;
  width: 100%;
  clear: both;
  font-size: 13px;
}

.paginationComponent .pagination {
  margin: 0;
  display: inline-block;
}

.paginationComponent .pagination li {
  display: inline-block;
  line-height: 36px;
}

.paginationComponent .pagination li.items.icons {
  padding: 0 3px;
}

.paginationComponent .pagination li.items.icons a {
  padding: 0px;
}

.paginationComponent .pagination li.items.icons.prev {
  padding-right: 10px;
}

.paginationComponent .pagination li.items.icons.next {
  padding-left: 10px;
}

.paginationComponent .pagination li.items {
  padding: 0 10px;
}

.paginationComponent .pagination li a {
  padding: 2px;
  color: #2E3F55;
}
.paginationComponent .pagination li a .fonticon{
  color: #00CEFF;
}
.paginationComponent .pagination li.disabled a .fonticon{
  color: #BBBBBB;
}

.paginationComponent .pagination li a img {
  width: 20px;
}

.paginationComponent .pagination li a, .paginationComponent .pagination li a:hover, .paginationComponent .pagination li a:focus {
  border: 0;
  text-decoration: none;
}

.paginationComponent .pagination li.active a {
  text-decoration: none;
  color: #fff;
  background-color: #035E82;
}

.paginationComponent .left-panel {
  display: inline-block;
  width: 49%;
  text-align: left;
  height: 36px;
  padding-left: 20px;
}

.paginationComponent .right-panel {
  width: 49%;
  text-align: right;
  height: 36px;
  vertical-align: top;
  display: inline-block;
}

.paginationComponent .right-panel .txt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 36px;
 
}
.paginationComponent .right-panel .txt .recordText {
  font-style: italic;
  color: #AEAEAE;
}
.paginationComponent .right-panel .pipe {
  display: inline-block;
  width: 1px;
  background-color: #aaa;
  height: 18px;
  margin: 0px 10px;
}

.paginationComponent .right-panel .pagination-records {
  width: 70px;
}

.paginationComponent .right-panel .pagination-records .Select-value {
  width: 40px;
}

.paginationComponent .right-panel .input-page {
  height: 26px;
  width: 106px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 5px;
}

.paginationComponent .right-panel .input-page:focus {
  outline: none;
}

.paginationComponent .right-panel .input-page.error {
  border: 1px solid #f00;
}

.paginationComponent .right-panel .Select-menu-outer {
  z-index: 5;
}

.paginationComponent .right-panel .Select-menu-outer {
  position: absolute !important;
  top: auto !important;
  bottom: calc(100% - 1px) !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.paginationComponent .right-panel .is-open .Select-control {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.paginationComponent .right-panel .input-page::placeholder {
  color: #ccc;
}

.icon-setting {
  height: 23px;
  width: 28px;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 100%;
}

.icon-rapid {
  height: 50px;
  width: 28px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
}

.icon-rapid .badge-rapid {
  width: 12px;
  height: 12px;
  position: absolute;
  top: -3px;
  right: -3px;
  background-color: #297add;
  border-radius: 50%;
  border: 1px solid #fff;
}
.rapid-annotation-pop .dropdown-menu {
  min-width:400px;
  padding:20px;
}

.icon_radio_checked {
  height: 20px;
  width: 25px;
  background-image: url(../images/btn_checked.png);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 75%;
  vertical-align: middle;
  cursor: pointer;
}

.icon_radio_unchecked {
  height: 20px;
  width: 25px;
  background-image: url(../images/btn_unchecked.png);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 75%;
  vertical-align: middle;
  cursor: pointer;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  font-weight: normal;
  line-height: 24px;
}

.modal-open .modal {
  background-color: rgba(0, 0, 0, .5);
}

.modal-container {
  padding: 25px;
}

.modal-footer {
  border-top: 1px solid #ebebeb;
}

.modal-footer .trialButton.addNewTrial {
  margin-top: 10px;
}

.modal_body_content .Select.select-role {
  width: 372px;
}

.Data-Management .nav-link {
  font-family: 'roboto-medium';
  font-size: 12px;
  background-color: #EDEDED;
  text-align: center;
  padding: 10px 30px;
  width: 200px;
  color: #2E3F55;
}

.Data-Management .nav-link:hover {
  background-color: #eee;
}

.Data-Management .nav-link.active, .Data-Management .nav-link.active:focus, .nav-links.active:hover {
  border: none;
  color: #00b5e0;
}

.alt-bg:nth-child(even) {
  background-color: #fbfbfb;
}

.upload_project table {
  width: 100%
}

.upload_project input {
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
  padding-left: 12px;
  width: 50%;
}

.icon_toggle {
  height: 20px;
  width: 20px;
  background-image: url(../images/toggle_icn.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  cursor: pointer;
}

.group-one, .group-two {
  margin-left: 50px;
  cursor: default;
  position: relative;
}

.group-one:after {
  content: '';
  position: absolute;
  left: -20px;
  top: 9px;
  height: 23px;
  width: 2px;
  background-color: #c0c0c0;
}

.group-one:before {
  content: '';
  position: absolute;
  left: -20px;
  top: 9px;
  height: 2px;
  width: 10px;
  background-color: #c0c0c0;
}

.group-two:before {
  content: '';
  position: absolute;
  left: -20px;
  top: 9px;
  height: 2px;
  width: 10px;
  background-color: #c0c0c0;
}

.group-one-study, .group-two-study {
  margin-left: 50px;
  cursor: default;
  position: relative;
  padding: 8px;
}

.group-one-study:after {
  content: '';
  position: absolute;
  left: -20px;
  top: 20px;
  height: 36px;
  width: 2px;
  background-color: #c0c0c0;
}

.group-one-study:before {
  content: '';
  position: absolute;
  left: -20px;
  top: 18px;
  height: 2px;
  width: 10px;
  background-color: #c0c0c0;
}

.group-two-study:before {
  content: '';
  position: absolute;
  left: -20px;
  top: 18px;
  height: 2px;
  width: 10px;
  background-color: #c0c0c0;
}

.havebreak.popover.bs-popover-bottom {
  margin-top: -10px;
}

.havebreak.popover.bs-popover-right {
  left: 0px !important;
  top: 0px !important;
}

.havebreak.popover.bs-popover-right .arrow {
  margin-top: -10px;
}

.preview-annotation {
  min-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.preview-annotation .modal-content {
  height: 100vh;
  border: 0;
  border-radius: 0;
  overflow: scroll;
}

.preview-annotation .modal-body {
  padding: 0;
}

.preview-container .left-menu {
  display: inline-block;
  width: 210px;
}

.preview-container .right-container {
  display: inline-block;
  width: 80%;
}

.modal-body {
  padding: 0px;
}

.modal-body .modal_body_content {
  padding: 0px;
}

.btn-logout {
  border: none;
  background: #00b5e0;
  outline: none;
  color: #fff;
  padding: 9px 50px;
  border-radius: 4px;
  font-size: 16px;
  margin: 4px;
}

.error-msg {
  padding: 30px 0;
}

.modal-header {
  border-bottom: 2px solid #ebebeb;
  font-size: 18px;
  padding: 15px 15px 6px;
  color: #333;
  background: transparent;
  font-family: "roboto-regular";
}

.modal-title {
  font-size: 22px;
  padding-left: 2px;
  padding-bottom: 2px;
}

.modal-label {
  font-family: 'roboto-regular';
  color: #333;
  font-size: .875rem;
}

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem;
  color: #00b5e0;
}

.custom-checkbox input:checked~.custom-control-label::after {
  background-color: #00b5e0;
  border-radius: .25rem;
}

/* HeaederAccolrdion*/

.header-accordion .panel-title, .subheader-accordion .panel-title {
  /* padding-left:20px; */
  margin: 2px;
  line-height: 34px;
}

.header-accordion .titleText, .subheader-accordion .titleText {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 0;
  display: inline-block;
}

.header-accordion .accordionContainer {
  /* padding-left:45px; */
}

.subheader-accordion .panel-default>.panel-heading {
  background-color: #fff;
}

#selectMessagePopup {
  z-index:999 !important;
}