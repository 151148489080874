.setting-modals.modal-lg{
    max-width: 950px;
}
.setting-modals.modal-content{
    border-radius: 0;
    border: none;
}

.nav-tabs-update {
    border-bottom: none;
    height: 38px;
    border-radius: 5px;
    justify-content: center;
}
.nav-tabs-update .nav-link {
    font-family: 'roboto-medium';
    font-size: 12px;
    background-color: #EDEDED !important;
    text-align: center;
    padding: 10px 30px;
    width: 300px;
    height: 100%;
}
.nav-tabs-update .nav-link.active {
    background-color: #2E3F55 !important;
    color: #fff !important;
    font-family: 'roboto-bold';
}
.nav-tabs-update .nav-link:first-child {
    border-radius: 5px 0 0 5px;
}
.nav-tabs-update .nav-link:last-child {
    border-radius: 0px 5px 5px 0px;
}
.nav-tabs-update .nav-link:focus {
    color: #fff;
}
.nav-tabs-update .tab-popup-wrapper{
    margin-top: 15px;
}