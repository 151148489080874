.technology-page .mainAccordian .background-light{
    background: #fbfbfb !important;
  }
.technology-page .mainAccordian .background-light:hover{
    background: #e8f0f8 !important;
  }
.technology-page .mainAccordian .panel-heading h4{
    padding-top: 12px !important;
  }
  .technology-page .mainAccordian .panel-heading{
    background: #fff;
  }
  .tbl-tech-detail{
    width: 100%;
  }
.tbl-tech-detail th{
    background: #efefef !important;
    padding: 8px;
    font-weight: 500;
}
.tbl-tech-detail th:first-child{
    padding-left: 21px;
}
.tbl-tech-detail td{
    padding: 8px;
}
.tbl-tech-detail td:first-child{
    padding-left: 24px;
}
.tbl-tech-detail tr:nth-child(even){
    /* background: #fbfbfb; */
}
.tbl-tech-detail tr:hover{
    /* background: #e8f0f8; */
}

.tbl-tech-detail tbody tr {
  border-bottom: 0.5px solid #BBBBBB;
}